// palette for Slots Jackpot Casino TODO colors and favicon needed
$bg-color: #010a2f;
$text-color: #e0e1c7;
$primary: #0086da;
$success: #66F27B;
$info: #009cda;
$danger: #fc3436;

//hamburger-menu in primary color
$navbar-dark-icon-color: $primary;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");


$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;

#rivalHeaderNavAndLogoSection {
  img {
    margin-top: -5px;
    max-height: 50px;
  }
}


//menu in primary color
.sticky-sidebar {
  .nav-link:hover, .active-link {
    color: $primary !important;
    .variable-svg-icon {
      background-color: $primary !important;
    }
  }
}
